import React, {useEffect, useState} from 'react';
import {Dropdown} from '@partssourceinc/react-ui-core';
import {useSelector, useDispatch} from 'react-redux';
import {actionCreators as SettingsActions} from 'stores/Settings'

export default function UserSelector({onUserSelected}) {
    const dispatch = useDispatch();

    const [selectedUser, setSelectedUser] = useState('');
    const users = useSelector(state => state.settings.users);
    const dataConfiguratorSelectedUserId = useSelector(state => state.settings.dataConfiguratorSelectedUserId);

    const {setDataConfiguratorSelectedUserId} = SettingsActions;
    
    useEffect(() => {
        setSelectedUser(dataConfiguratorSelectedUserId ?? '');
    }, [dataConfiguratorSelectedUserId]);

    const selectUser = (userId) => {
        dispatch(setDataConfiguratorSelectedUserId(userId));
        if (onUserSelected) 
            onUserSelected(selectedUser);
    }

    return (
        <Dropdown
            id="usersDropdown"
            name="selectedUser"
            placeholder=""
            label="Select User"
            options={users.map(user => ({
                value: user.contactId,
                text: user.fullName,
            }))}
            onChange={(e, data) => selectUser(data.value)}
            selectedValue={selectedUser}
            required={true}
        />
    );
}
