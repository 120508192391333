import React, { useState, useEffect } from 'react';
import UserSelector from 'components/UserSelector';
import styled from 'styled-components';
import SelectedUserPasswordInputDialog from '../SelectedUserPasswordInputDialog';
import { CircularProgressbar } from 'react-circular-progressbar';
import './../../../less/Settings/circularProgressbar.less';
import moment from 'moment';
import PopupAdmin from '../../PopupAdmin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBan} from '@fortawesome/pro-regular-svg-icons/faBan';
import axios from 'axios';
import {useSelector} from 'react-redux';

const Container = styled.div`
`;

const TwoColumnRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TwoColumnRowLeftCell = styled.div`
    flex-basis: 50%; 
    text-align: left;
`;

const TwoColumnRowRightCell = styled.div`
    flex-basis: 50%; 
    text-align: right;
    display: flex;
    justify-content: flex-end;
`;

const UserContainer = styled.div`
    max-width: 600px;
`;

const ProgressContainer = styled.div`
    width: 100px;
    height: 100px;
    position: relative;
`;

const ProgressBarContainer = styled.div`
    position: absolute;
`;

const ProgressBarTextContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ProgressText = styled.div`
    height: 100px;
    vertical-align: top;
    padding-top: 10px;
`;

const GeneratingText = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 93, 166, 1);
    text-align: left;
    line-height:30px;
    margin-left:10px;
`;
const CompletedText = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: rgba(23, 171, 120, 1);
    text-align: left;
    line-height:30px;
    margin-left:10px;
`;

const HadErrorsText = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: red;
    text-align: left;
    line-height:30px;
    margin-left:10px;
`;

const DateInfo = styled.div`
    font-size: 16px;
    color: rgba(0, 0, 0, 0.2);
    text-align: left;
    font-weight: bold;
    margin-left:15px;
`;

const ConfirmationMessageContainer = styled.div`
    font-size: 16px;
    margin-top: 20px;
`;

const CancelConfirmation = styled(PopupAdmin)`
    h1 { margin: 0; }
`;

const CancelIconButton = styled(FontAwesomeIcon)`
    color: red;
    margin-left: 3px;
    margin-right: 5px; 
`;
const CancelContainer = styled.div`
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    color: red;
    margin-top: 12px;
    text-align: left;
    font-weight: bold;
    margin-left:15px;
`;

const Cancelling = styled.div`
    font-size: 16px;
    color: #FF9505;
    text-align: left;
    font-weight: bold;
    margin-left:15px;
`;

export default function DataConfigurationHeader({ title, loaded, progressInfo }) {

    const [percentage, setPercentage] = useState(0);
    const [centerText, setCenterText] = useState('');
    const [pathStrokeColor, setPathStrokeColor] = useState('rgba(0, 93, 166, 1)');
    const [trailStrokeColor, setTrailStrokeColor] = useState('rgba(0, 93, 166, 1)');
    const [isGenerating, setIsGenerating] = useState(false);
    const [isComplete, setisComplete] = useState(false);
    const [hadErrors, setHasErrors] = useState(false);
    const [wasCancelled, setWasCancelled] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const facilityId = useSelector(state => state.settings.facility.facilityId);
    const userId = useSelector(state => state.settings.dataConfiguratorSelectedUserId);

    useEffect(() => {
        let perc = 0;
        let text = '';
        let color = 'rgba(0, 93, 166, 1)';
        let trailColor = 'rgba(0, 93, 166, 0.15)';
        if (progressInfo && progressInfo.totalCount !== 0) {
            const doneItems = (progressInfo.successCount ?? 0) + (progressInfo.failedCount ?? 0);
            perc = (doneItems / progressInfo.totalCount) * 100;
            text = `${doneItems} of ${progressInfo.totalCount}`;
            switch (progressInfo.generatingStatusId) {         
                case 1:
                    setIsGenerating(true);
                    setisComplete(false);
                    setHasErrors(false);
                    setWasCancelled(false);
                    color = 'rgba(0, 93, 166, 1)';  // blue
                    trailColor = 'rgba(0, 93, 166, 0.15)';
                    break;
                case 2:
                    setIsGenerating(false);
                    setisComplete(true);
                    setHasErrors(false);
                    setWasCancelled(false);
                    color = 'rgba(23, 171, 120, 1)'; // green
                    trailColor = 'rgba(23, 171, 120, 0.15)'; // green
                    break;
                case 3:
                    setIsGenerating(false);
                    setisComplete(false);
                    setHasErrors(true);
                    setWasCancelled(false);
                    color = 'red';  // red
                    trailColor = 'rgba(255, 0, 0, 0.15)';
                    break;
                case 4:
                    setIsGenerating(false);
                    setisComplete(false);
                    setHasErrors(false);
                    setWasCancelled(true);
                    color = 'red';  // red
                    trailColor = 'rgba(255, 0, 0, 0.15)';
                    break;
            }
        }

        setPercentage(perc);
        setCenterText(text);
        setPathStrokeColor(color);
        setTrailStrokeColor(trailColor);
    }, [progressInfo]);

    useEffect(() => {
        if (!isGenerating)
            setCancelling(false);
    }, [isGenerating]);

    const openCancelConfirmation = () => {
        setShowCancelConfirmation(true);
    }

    const cancelBatch = () => {      
        setCancelling(true);
        axios.delete(`${process.env.REACT_APP_DFA_API}/api/${progressInfo.batchType}/batch/cancel?FacilityId=${facilityId}&UserId=${userId}`).then(() => {     
            setShowCancelConfirmation(false);
        });
    }
    return (
        <Container>
            <TwoColumnRow>
                <TwoColumnRowLeftCell>
                    <h1>{title}</h1>
                    <UserContainer>
                        <UserSelector />
                    </UserContainer>
                </TwoColumnRowLeftCell>
                <TwoColumnRowRightCell>
                    {progressInfo &&
                        <div style={{ width: '400px', display: 'flex' }}>
                            <ProgressContainer>
                                <ProgressBarContainer>
                                    <CircularProgressbar value={percentage}
                                        styles={{
                                            path: {
                                                stroke: pathStrokeColor,
                                                strokeLinecap: 'butt',
                                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                                transform: 'rotate(0.5turn)',
                                                transformOrigin: 'center center',
                                            },
                                            trail: {
                                                stroke: trailStrokeColor,
                                                strokeLinecap: 'butt',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                            text: { fill: '#2E2F30', fontSize: '16px' },
                                            background: { fill: '#f0ad4e' },
                                        }}
                                    />                                
                                </ProgressBarContainer>
                                <ProgressBarTextContainer>
                                    <div style={{fontSize: '22px'}}>Item</div>
                                    <div style={{fontSize: '16px', fontWeight: 'bold'}}>{centerText}</div>
                                </ProgressBarTextContainer>
                            </ProgressContainer>
                            <div style={{ width: '300px' }}>
                                <ProgressText>
                                    {isGenerating && (<GeneratingText>
                                        Generating Data
                                    </GeneratingText>)}
                                    {isComplete && (<CompletedText>
                                        Generated Successfully
                                    </CompletedText>)}
                                    {hadErrors && (<HadErrorsText>
                                        Generated with Error(s)
                                    </HadErrorsText>)}
                                    {wasCancelled && (<HadErrorsText>
                                        Batch Cancelled
                                    </HadErrorsText>)}
                                    {progressInfo?.dateLastStarted && (<DateInfo>
                                        Date Started: {moment(progressInfo.dateLastStarted).format('MM/DD/YYYY h:mm A')}
                                    </DateInfo>)}
                                    {progressInfo?.dateLastCompleted && (<DateInfo>
                                        Date Completed: {moment(progressInfo.dateLastCompleted).format('MM/DD/YYYY h:mm A')}
                                    </DateInfo>)}
                                    {progressInfo?.dateLastCancelled && (<DateInfo>
                                        Date Cancelled: {moment(progressInfo.dateLastCancelled).format('MM/DD/YYYY h:mm A')}
                                    </DateInfo>)}
                                    {isGenerating && !cancelling && (<CancelContainer 
                                        onClick={() => openCancelConfirmation()} >
                                        <CancelIconButton icon={faBan}/>Cancel current batch
                                    </CancelContainer>)}
                                    {cancelling && <Cancelling>Cancelling...</Cancelling>}
                                </ProgressText>
                            </div>
                        </div>}
                    {!progressInfo && loaded && (<div style={{ width: '400px', display: 'flex' }}>
                            <ProgressContainer>
                                <ProgressBarContainer>
                                    <CircularProgressbar value={0}
                                        styles={{
                                            path: {
                                                stroke: 'rgba(0, 93, 166, 1)',
                                                strokeLinecap: 'butt',
                                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                                transform: 'rotate(0.5turn)',
                                                transformOrigin: 'center center',
                                            },
                                            trail: {
                                                stroke: 'rgba(0, 93, 166, 0.15)',
                                                strokeLinecap: 'butt',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                            text: { fill: '#2E2F30', fontSize: '16px' },
                                            background: { fill: '#f0ad4e' },
                                        }}
                                    />
                                </ProgressBarContainer>
                                <ProgressBarTextContainer>
                                    <div style={{fontSize: '22px'}}>Item</div>
                                    <div style={{fontSize: '16px', fontWeight: 'bold'}}>0 of 0</div>
                                </ProgressBarTextContainer>
                            </ProgressContainer>
                            <div style={{ width: '300px' }}>
                                <ProgressText>
                                    <GeneratingText>
                                        Not Started
                                    </GeneratingText>
                                    <DateInfo>
                                        Date Started: N/A
                                    </DateInfo>
                                </ProgressText>
                            </div>
                        </div>)}
                </TwoColumnRowRightCell>
            </TwoColumnRow>
            <SelectedUserPasswordInputDialog />
            {showCancelConfirmation &&
                <CancelConfirmation confirmText="Yes"
                    cancelText="No"
                    show={true}
                    onConfirm={() => { cancelBatch(); }}
                    onCancel={() => { setShowCancelConfirmation(false); }}
                >
                    <div>
                        <ConfirmationMessageContainer>Are you sure you want to cancel this batch generating?</ConfirmationMessageContainer>
                    </div>
                </CancelConfirmation>}
        </Container>
    )
}
