import React from 'react';
import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileInvoiceDollar, faLongArrowLeft, faArrowsH} from '@fortawesome/pro-regular-svg-icons';
import 'less/admin/adminsettings.less';
import DataConfigurationHeader from './DataConfigurationHeader';
import styled from 'styled-components';
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 40px 40px 40px;
`;

const DataConfiguration = () => {
    const history = useHistory();

    const handleNavigation = (path) => {
        history.push(path);
    };

    return (
        <><HeaderContainer>
            <DataConfigurationHeader title="Data Configuration" />
        </HeaderContainer><div className="admin-settings">
            <div>
                <div className="settings-section">
                    <i className="glyphicon glyphicon-user" />
                    <div className="section-name" onClick={() => handleNavigation('/admin/dataconfig/user-settings')}>User Settings</div>
                    <div className="section-description">Various user specific input data</div>
                </div>
                <div className="settings-section">
                    <i className="glyphicon glyphicon-list-alt" aria-hidden="true" />
                    <div className="section-name" onClick={() => handleNavigation('/admin/dataconfig/userlists')}>User Lists</div>
                    <div className="section-description">Add, edit, and delete user lists. Assign items to a specific list</div>
                </div>
                <div className="settings-section">
                    <FontAwesomeIcon icon={faFileInvoiceDollar} style={{fontSize: '24px'}} />
                    <div className="section-name" onClick={() => handleNavigation('/admin/dataconfig/quotes')}>Quotes</div>
                    <div className="section-description">Add, edit and delete quote input data. Info will show up on the Quotes screen</div>
                </div>
                <div className="settings-section">
                    <i className="fa fa-globe" aria-hidden="true" />
                    <div className="section-name" onClick={() => handleNavigation('/admin/dataconfig/partorders')}>Part Orders</div>
                    <div className="section-description">Add, edit, and delete part input data. Info will show up on the Orders screen</div>
                </div>
                <div className="settings-section">
                    <FontAwesomeIcon icon={faScrewdriverWrench} style={{fontSize: '24px'}} />
                    <div className="section-name" onClick={() => handleNavigation('/admin/dataconfig/repairs')}>Depot Repairs</div>
                    <div className="section-description">Add, edit, and delete repair input data. Info will show up on the Depot Repairs screen</div>
                </div>
                <div className="settings-section">
                    <FontAwesomeIcon icon={faScrewdriverWrench} style={{fontSize: '24px'}} />
                    <div className="section-name" onClick={() => handleNavigation('/admin/dataconfig/quotedrepairs')}>Quoted Repairs</div>
                    <div className="section-description">Add, edit, and delete quoted repair input data. Info will show up on the Quoted Repairs screen</div>
                </div>
                <div className="settings-section">
                    <FontAwesomeIcon icon={faArrowsH} style={{fontSize: '24px'}} />
                    <div className="section-name" onClick={() => handleNavigation('/admin/dataconfig/exchanges')}>Exchanges</div>
                    <div className="section-description">Add, edit, and delete exchange input data. Info will show up on the Exchanges & Returns screen</div>
                </div>
                <div className="settings-section">
                    <FontAwesomeIcon icon={faLongArrowLeft} style={{fontSize: '24px'}} />
                    <div className="section-name" onClick={() => handleNavigation('/admin/dataconfig/returns')}>Returns</div>
                    <div className="section-description">Add, edit and delete return input data. Info will show up on the Exchanges & Returns screen</div>
                </div>
                <div className="settings-section">
                    <i className="glyphicon glyphicon-list-alt" aria-hidden="true" />
                    <div className="section-name-disabled">Loaners</div>
                    <div className="section-description">Add, edit and delete return input data. Info will show up on the Loaners screen</div>
                </div>
            </div>
        </div></>
    );
}

export default DataConfiguration;
