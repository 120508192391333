import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Popup, TextField } from '@partssourceinc/react-ui-core/components';
import styled from 'styled-components';
import { useLocation } from 'react-router';

const CustomTextField = styled(TextField)`
    color: rgb(74, 74, 74);
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    max-width: 390px;

    label {
      padding: 0px !important;
      text-align: justify !important;
    }
`;

const SelectedUserPasswordInputDialog = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [formWasTouched, setFormWasTouched] = useState(false);
    const dataConfiguratorSelectedUserId = useSelector(state => state.settings.dataConfiguratorSelectedUserId);
    const facilityId = useSelector(state => state.settings.facility.facilityId);
    const [shouldOpenModal, setShouldOpenModal] = useState(false);
    const [password, setPassword] = useState('');
    const users = useSelector(state => state.settings.users);

    const handleCloseModal = () => {
        setShouldOpenModal(false);
        setPassword('');
        setFormWasTouched(false);
    }

    useEffect(() => {
        if (dataConfiguratorSelectedUserId) {
            axios
                .get(`${process.env.REACT_APP_DFA_API}/api/users/${dataConfiguratorSelectedUserId}`)
                .then(response => {
                    if (!response.data.password && !location.pathname.includes('user-settings')) 
                        setShouldOpenModal(true);
                    else setShouldOpenModal(false);
                })
                .catch(error => console.log(error));
        }
    }, [dataConfiguratorSelectedUserId]);

    const setUserPassword = async () => {
        setIsLoading(true);
        const user = users.find(c => c.contactId == dataConfiguratorSelectedUserId)
        console.log(user)
        const request = {
            userId: dataConfiguratorSelectedUserId,
            password: password,
            firstName: user?.firstName,
            lastName: user?.lastName,
            username: user?.loginUserId,
            facilityId: facilityId
        }
        try {
            await axios.post(`${process.env.REACT_APP_DFA_API}/api/users/settings`, request);
        } catch (error) {
            console.log(error);
        }

        handleCloseModal();
    }

    return (<Popup
        show={shouldOpenModal}
        onConfirm={async () => await setUserPassword()}
        onCancel={() => handleCloseModal()}
        disableConfirm={!password || isLoading}
        loading={isLoading}
    >
        <span>{`Please enter the password for ${users.find(c => c.contactId == dataConfiguratorSelectedUserId)?.fullName} to use to generate data.`}</span>
        <CustomTextField
            id="password"
            type="password"
            text={password}
            required={true}
            label="Password"
            placeholder="Password"
            onChange={(e) => {
                setPassword(e.target.value)
                setFormWasTouched(true);
            }}
            showErrorMessage={(!password && formWasTouched) && 'field is required'}
        />
    </Popup>);
}

export default SelectedUserPasswordInputDialog;